import { useEffect, useState } from 'react';
import { queryData } from '../services/appServices';
import { useNavigate } from 'react-router-dom';

export function useGetData ({ column }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const results = await queryData({ column });
      if (results.error) {
        navigate('/ServerError');
      } else if (results.ok) {
        setData(results.data);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [column]);

  return { data, getData, error, isLoading };
}
