/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable object-shorthand */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import React, { forwardRef, useEffect, useState } from 'react';

const TextArea = forwardRef(
  ({ minHeight = '50px', width, content = '', placeholder = '' }, ref) => {
    const [height, setHeight] = useState(minHeight);
    useEffect(() => {
      if (ref.current) {
        ref.current.style.height = minHeight;
        ref.current.style.height = `${ref.current.scrollHeight}px`;
      }
    }, [content, ref, width]);
    const handleHeight = (e) => {
      e.target.style.height = minHeight;
      e.target.style.height = `${e.target.scrollHeight}px`;
    };
    const styles = {
      width: '100%',
      padding: '10px',
      outline: 'none',
      resize: 'none',
      fontSize: '18px',
      borderRadius: '5px',
      minHeight: minHeight,
      scrollbarWidth: 'none', // Firefox
      msOverflowStyle: 'none',
      '::WebkitScrollbar': {
        width: '0px',
      },
      color: 'black',
    };
    return (
      <textarea
        ref={ref}
        style={styles}
        defaultValue={content}
        placeholder={placeholder}
        onChange={(e) => handleHeight(e)}
      />
    );
  }
);

export default TextArea;
