import Home from '../pages/Home';
import Equipe from '../pages/LaCompagnie';
import Spectacles from '../pages/Spectacles';
import Agenda from '../pages/Agenda';
import Contact from '../pages/Contact';
import Admin from '../pages/Admin';
import Conexion from '../pages/Conexion';
import ErrorPage from '../pages/ErrorPage';

const routes = [
  { path: '/', element: <Home />, exact: true },
  { path: '/LaCompagnie', element: <Equipe /> },
  { path: '/Spectacles', element: <Spectacles /> },
  { path: '/Agenda', element: <Agenda /> },
  { path: '/Contact', element: <Contact /> },
  { path: '/Admin', element: <Admin />, requiresAuth: true },
  { path: '/Conexion', element: <Conexion />, requiresAuth: false },
  { path: '/ServerError', element: <ErrorPage error="500" /> },
  { path: '*', element: <ErrorPage error="404" /> },
];

export default routes;
