/* eslint-disable no-unused-vars */
import '../styles/App.css';
import '../styles/styles.css';
import AnimatedRoutes from './AnimatedRoutes.js';
import Header from './Header.js';
import Footer from './Footer';
import { HelmetProvider } from 'react-helmet-async';

function App () {
  return (
    <HelmetProvider>
      <Header />
        <AnimatedRoutes/>
      <Footer />
    </HelmetProvider>
  );
}

export default App;
