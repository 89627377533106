/* eslint-disable no-unused-vars */
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { IMAGE_BASE_URL } from '../confing';

function CarrouselPhotos({ props }) {
  const { photos, currentPhoto } = props;
  const [currentIndex, setCurrentIndex] = useState(currentPhoto);
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        handlePrevious();
      } else if (e.key === 'ArrowRight') {
        handleNext();
      }
    };
    window.addEventListener('keydown', (e) => handleKeyDown(e));

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const slideVariants = {
    hiddenRight: {
      opacity: 0,
    },
    hiddenLeft: {
      opacity: 0,
    },
    visible: {
      x: '0',
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  };
  const dotsVariants = {
    initial: {
      y: 0,
    },
    animate: {
      y: -10,
      scale: 1.2,
      transition: { type: 'spring', stiffness: 1000, damping: '10' },
    },
    hover: {
      scale: 1.1,
      transition: { duration: 0.2 },
    },
  };

  const handleNext = () => {
    setDirection('right');
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === photos.length ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setDirection('left');
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setDirection(index > currentIndex ? 'right' : 'left');
    setCurrentIndex(index);
  };

  return (
    <div className="container_carrousel_photos">
      <motion.div className="left" onClick={handlePrevious}>
        <ArrowBackIosNewOutlinedIcon />
      </motion.div>
      <AnimatePresence mode="wait">
        <motion.img
          key={currentIndex}
          src={`${IMAGE_BASE_URL}/images/spectacles/${photos[currentIndex]}`}
          initial={direction === 'right' ? 'hiddenRight' : 'hiddenLeft'}
          animate="visible"
          exit="exit"
          variants={slideVariants}
        />
      </AnimatePresence>
      <motion.div className="right" onClick={handleNext}>
        <ArrowForwardIosOutlinedIcon />
      </motion.div>
      <div className="carousel_indicator">
        {photos.map((_, index) => (
          <motion.div
            key={index}
            className={`dot ${currentIndex === index ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
            initial="initial"
            animate={currentIndex === index ? 'animate' : ''}
            whileHover="hover"
            variants={dotsVariants}
          ></motion.div>
        ))}
      </div>
    </div>
  );
}

export default CarrouselPhotos;
