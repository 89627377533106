/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
import React from 'react';
import { deleteItem } from '../services/appServices';
export const AdminFormDelete = ({ item, column, handleBackdrop }) => {
  const handleMiniImagesPaths = (path) => {
    const cutPoint = path.lastIndexOf('/');
    return `${path.slice(0, cutPoint + 1)}mini-${item.background.slice(cutPoint + 1)}`;
  };
  const handleDelete = async (id) => {
    const paths = [];
    if (item.background) {
      paths.push(`/images/${column}/${item.background}`);
      paths.push(`/images/miniatures/${item.background}`);
    }
    if (item.images) {
      const { images } = item;
      images.forEach(image => {
        paths.push(`/images/${column}/${image}`);
        paths.push(`/images/miniatures/${image}`);
      });
    }
    const response = await deleteItem({ column, id, paths });
    if (response.ok) {
      window.alert('Item eliminado correctamente');
      window.location.reload();
    } else {
      console.log(response);
    }
  };
  return (
		<div className='admin_form'>
			<p>Eliminar este item de la lista?</p>
			<button onClick={() => handleDelete(item.id)}>Confirmar</button>
			<button onClick={handleBackdrop}>Cancelar</button>
		</div>
  );
};
