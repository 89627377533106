/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IMAGE_BASE_URL } from '../confing';
import {
  insertSpectacle,
  translateText,
  updateSpectacle,
} from '../services/appServices';
import TextArea from './TextArea';

function AdminFormInsertSpectacle({ itemToUpdate, handleBackdrop }) {
  const textAreaRef = useRef(null);
  const [textAreaValues, setTextAreaValues] = useState({
    fr: '',
    esp: '',
    en: '',
  });
  const [currentLanguage, setCurrentLanguage] = useState('fr');
  const [infos, setInfos] = useState([]);
  const [files, setFiles] = useState([]);
  const [affiche, setAffiche] = useState('');
  const [prevImages, setPrevImages] = useState([]);
  const [translationTarget, setTranslationTarget] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // this useEffect update the data to the form if it's a update case
  useEffect(() => {
    if (itemToUpdate && itemToUpdate.description) {
      const descriptionValues = {
        fr: itemToUpdate.description.fr,
        esp: itemToUpdate.description.esp,
        en: itemToUpdate.description.en,
      };
      setTextAreaValues(descriptionValues);
      textAreaRef.current.value = itemToUpdate.description[currentLanguage];
      if (itemToUpdate.infos && itemToUpdate.infos.length > 0) {
        setInfos(itemToUpdate.infos);
      }
      setPrevImages(itemToUpdate.images);
      setAffiche(itemToUpdate.background);
    }
  }, [itemToUpdate]);
  // this useEffect update the text to the translate function
  useEffect(() => {
    const handleFocus = () => {
      if (
        document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA'
      ) {
        setTranslationTarget({
          value: document.activeElement.value,
          target: document.activeElement.id,
        });
      }
    };
    document.addEventListener('focusin', handleFocus);
    return () => {
      document.removeEventListener('focusin', handleFocus);
    };
  }, [translationTarget]);
  // revoke the data uris to avoid memory leaks, will run on unmount (useDrop documentation)
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: {
        'image/*': [],
      },
      // setting the files state with the files gived in the drop zone
      onDrop: (acceptedFiles) => {
        if (affiche === '') {
          setAffiche(acceptedFiles[0]);
        }
        setFiles((prevFiles) => [
          ...prevFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ]);
      },
    });
  // preview of the existents images (update case)
  const prevThumbs = prevImages.map((image, index) => (
    <div className="thumb" key={image}>
      <div className="thumbInner">
        <img
          src={`${IMAGE_BASE_URL}/images/spectacles/${image}`}
          className="img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(image);
          }}
        />
        <div className="hidden_thumb_delete">
          <DeleteOutlineOutlinedIcon
            onClick={() => handleDeleteImagePreview(index, true)}
          />
          <StarOutlineOutlinedIcon onClick={() => setAffiche(image)} />
        </div>
      </div>
    </div>
  ));
  // preview of the spectacle images added
  const thumbs = files.map((file, index) => (
    <div className="thumb" key={file.name}>
      <div className="thumbInner">
        <img
          src={file.preview}
          className="img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <div className="hidden_thumb_delete">
          <DeleteOutlineOutlinedIcon
            onClick={() => handleDeleteImagePreview(index)}
          />
          <StarOutlineOutlinedIcon onClick={() => setAffiche(file)} />
        </div>
      </div>
    </div>
  ));
  const handleDeleteImagePreview = (index, prev = false) => {
    if (prev) {
      const imagesUpdate = prevImages.toSpliced(index, 1);
      setPrevImages(imagesUpdate);
    }
    const filesUpdate = files.toSpliced(index, 1);
    setFiles(filesUpdate);
  };
  /* const handleTitleChange = (key, value) => {
    const newInfosTitles = [...infos];
    newInfosTitles[key].titre[currentLanguage] = value;
    setInfos(newInfosTitles);
  }; */
  const handleInfoChange = (key, value, element) => {
    const newInfosValues = [...infos];
    newInfosValues[key][element][currentLanguage] = value;
    setInfos(newInfosValues);
  };
  const handleAddInfo = () => {
    setInfos([
      ...infos,
      {
        titre: { fr: '', esp: '', en: '' },
        value: { fr: '', esp: '', en: '' },
      },
    ]);
    console.log(infos);
  };
  const handleDeleteInfo = (index) => {
    setInfos(infos.filter((_, i) => i !== index));
  };
  const handleTraduction = async (e) => {
    e.preventDefault();
    const elementTarget = document.getElementById(translationTarget.target);
    if (translationTarget !== '') {
      const result = await translateText({
        text: translationTarget.value,
        languageTarget: currentLanguage,
      });
      if (result.error) {
        window.alert(result.error);
        console.log(result);
      } else {
        if (elementTarget !== null) {
          elementTarget.value = result.translation;
        } else {
          textAreaRef.current.value = result.translation;
        }
      }
    }
  };

  const handleLanguageChange = (e, language) => {
    e.preventDefault();
    const newValuesTextArea = {
      ...textAreaValues,
      [currentLanguage]: textAreaRef.current.value,
    };
    setTextAreaValues(newValuesTextArea);
    setCurrentLanguage(language);
    textAreaRef.current.value = textAreaValues[language];
  };
  const handleBackgroundDrop = () => {
    if (affiche !== '') {
      if (typeof affiche === 'string') {
        return `url(${IMAGE_BASE_URL}/images/spectacles/${affiche})`;
      } else {
        return `url(${URL.createObjectURL(affiche)})`;
      }
    } else {
      return 'none';
    }
  };
  // the submit to the form data
  const handleSubmit = async (e) => {
    e.preventDefault();
    let message = '';
    let formValid = true;
    setIsSubmitting(true);

    const formData = new FormData(e.target);

    // setting the infos in the formData object
    if (infos.length > 0) {
      const jsonInfosData = JSON.stringify(infos);
      formData.set('infos', jsonInfosData);
    } else {
      formData.set('infos', null);
    }

    // setting the description current values
    const currentTextAreaValues = {
      ...textAreaValues,
      [currentLanguage]: textAreaRef.current.value,
    };
    setTextAreaValues(currentTextAreaValues);
    // verificando que todos los campos de description esten completos
    for (const [key, value] of Object.entries(currentTextAreaValues)) {
      if (value === '') {
        message += ` \nRemplizez la description: ${key}`;
        formValid = false;
        textAreaRef.current.classList.add('invalid_field');
      }
    }
    const descriptionData = JSON.stringify(currentTextAreaValues);
    formData.set('description', descriptionData);
    // this loop verify if all the INPUT of the form are complete
    const formInputs = e.target.elements;
    for (const [key, element] of Object.entries(formInputs)) {
      if (element.tagName === 'INPUT' && element.type !== 'file') {
        if (element.value === '') {
          element.classList.add('invalid_field');
          formValid = false;
          message = 'Completez tous les champs!';
        } else {
          element.classList.remove('invalid_field');
        }
      }
    }
    // setting the background row to the spectacles column
    // this will be in the index 0 of the req.files array
    if (typeof affiche === 'string') {
      formData.set('background', affiche);
    } else {
      formData.append('image', affiche);
    }
    // if is not are the affiche file de form is not valid
    if (affiche === '') formValid = false;
    // this verification check if we have images available
    if (!itemToUpdate && files.length <= 1) {
      formValid = false;
      message += ' \nIl faut ajouter des images!';
    } else {
      files.forEach((file) => {
        if (file !== affiche) {
          formData.append('image', file);
        }
      });
    }
    // this loop verification the info values in the three languages
    infos.forEach((info) => {
      if (
        info.titre.fr === '' ||
        info.titre.esp === '' ||
        info.titre.en === '' ||
        info.value.fr === '' ||
        info.value.esp === '' ||
        info.value.en === ''
      ) {
        formValid = false;
        message += '\nVous avez un champ du information vide!';
      }
    });
    if (formValid) {
      message = '';
      if (!itemToUpdate) {
        try {
          // insert query function
          const response = await insertSpectacle(formData);
          if (response.error) {
            setIsSubmitting(false);
            window.alert(response.error);
            console.log(response);
          } else {
            setIsSubmitting(false);
            window.alert('Spectacle agregado correctamente');
            window.location.reload();
          }
        } catch (error) {
          setIsSubmitting(false);
          console.error('Error al agregar el evento:', error);
          window.alert(
            'Error al agregar el evento. Por favor, inténtalo de nuevo más tarde.'
          );
        }
      } else {
        const imagesToDelete = itemToUpdate.images.filter(
          (image) => !prevImages.includes(image)
        );
        const imagesToSave = itemToUpdate.images.filter((image) =>
          prevImages.includes(image)
        );
        formData.set('imagesToDelete', JSON.stringify(imagesToDelete));
        formData.set('imagesToSave', JSON.stringify(imagesToSave));
        formData.set('spectacleId', itemToUpdate.id);
        formData.set('spectacleTitle', itemToUpdate.title);
        try {
          const response = await updateSpectacle(formData);
          if (response.error) {
            setIsSubmitting(false);
            window.alert(response.error);
          } else {
            setIsSubmitting(false);
            window.alert('Spectacle Actualizado correctamente');
            window.location.reload();
          }
        } catch (error) {
          setIsSubmitting(false);
          console.error('Error al actualizar el evento:', error);
          window.alert(
            'Error al actualizar el evento. Por favor, inténtalo de nuevo más tarde.'
          );
        }
      }
    } else {
      window.alert(message);
      setIsSubmitting(false);
    }
  };
  return (
    <div>
      <h3>
        {itemToUpdate != null ? 'Actualizer évènement' : 'Nouvel speectacle'}
      </h3>
      <form
        className="admin_form form_insert_spectacle"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <div className="insert_spectacle_left_block">
          <div
            {...getRootProps()}
            className="form_drop_box form_drop_box_spectacle"
            style={{
              backgroundColor: isDragActive ? '#3e3e3ec7' : 'none',
              backgroundImage: handleBackgroundDrop(),
              outline: affiche !== '' ? 'white 1px solid' : 'white 2px dashed',
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>{affiche !== '' ? '' : 'Suelta las imagenes aqui...'} </p>
            ) : (
              <p>
                {affiche !== '' ? '' : 'La primer imagen será el affiche...'}
              </p>
            )}
          </div>
          <aside className="thumbsContainer">
            {
              // preview of the existing images
              prevThumbs
            }
            {
              // preview of the new images added
              thumbs
            }
          </aside>
        </div>
        <div className="insert_spectacle_right_block">
          <label>
            Title *
            <input
              defaultValue={itemToUpdate ? itemToUpdate.title : ''}
              name="title"
              type="text"
              placeholder="Nom du évènement"
            />
          </label>
          <label>
            Link youtube du spectacle ( optionnel )
            <input
              defaultValue={itemToUpdate ? itemToUpdate.video_link : ''}
              name="video_link"
              type="text"
              placeholder="Link youtube du spectacle"
            />
          </label>
          <div className="admin_description_bts">
            <button onClick={(e) => handleLanguageChange(e, 'fr')}>
              French
            </button>
            <button onClick={(e) => handleLanguageChange(e, 'esp')}>
              Spanish
            </button>
            <button onClick={(e) => handleLanguageChange(e, 'en')}>
              English
            </button>
            <button onClick={(e) => handleTraduction(e)}>Traduir</button>
          </div>
          <TextArea
            ref={textAreaRef}
            placeholder={`Description ${currentLanguage}`}
            width={'100%'}
            minHeight="150px"
          />

          {infos.length === 0 ? (
            <p>Agregar infos</p>
          ) : (
            infos.map((info, key) => (
              <div key={key} className="insert_spectacle_info_block">
                <input
                  id={`titreInput_${key}`}
                  value={info.titre[currentLanguage]}
                  placeholder={`titre info ${currentLanguage}`}
                  onChange={(e) =>
                    handleInfoChange(key, e.target.value, 'titre')
                  }
                />
                <input
                  value={info.value[currentLanguage]}
                  placeholder={`info ${currentLanguage}`}
                  onChange={(e) =>
                    handleInfoChange(key, e.target.value, 'value')
                  }
                />
                <DeleteOutlineOutlinedIcon
                  onClick={() => handleDeleteInfo(key)}
                  className="info_block_btns"
                />
              </div>
            ))
          )}
          <AddOutlinedIcon
            className="insert_spectacle_insert_info_btn"
            onClick={handleAddInfo}
          />
          <button disabled={isSubmitting}>
            {isSubmitting ? 'En cours...' : 'Confirmer'}
          </button>
          <button onClick={handleBackdrop}>Cancelar</button>
        </div>
      </form>
    </div>
  );
}

export default AdminFormInsertSpectacle;
