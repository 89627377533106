/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import reportWebVitals from './reportWebVitals';

import global_fr from './Translations/fr/grlobal.json';
import global_esp from './Translations/esp/global.json';
import global_en from './Translations/en/global.json';
import pages_fr from './Translations/fr/pages.json';
import pages_esp from './Translations/esp/pages.json';
import pages_en from './Translations/en/pages.json';

import { GoogleOAuthProvider } from '@react-oauth/google';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'fr',
  resources: {
    esp: {
      global: global_esp,
      pages: pages_esp,
    },
    en: {
      global: global_en,
      pages: pages_en,
    },
    fr: {
      global: global_fr,
      pages: pages_fr,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const clientId =
  '613778346271-mvf83o1grj00tn2c09dqftj3ktl7bgcm.apps.googleusercontent.com';
root.render(
  <I18nextProvider i18n={i18next}>
    <GoogleOAuthProvider clientId={clientId}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GoogleOAuthProvider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
