/* eslint-disable no-unused-vars */
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { IMAGE_BASE_URL } from '../confing';
import Backdrop from './Backdrop';
import CarrouselPhotos from './CarrouselPhotos';
import ContainerCards from './ContainerCards';
import './SpectacleView.css';

// eslint-disable-next-line space-before-function-paren
function SpectacleViewPhotos({ photos }) {
  const [photoSelected, setPhotoSelected] = useState(null);
  const handleCloseBackdrop = () => {
    setPhotoSelected(null);
  };
  return (
    <ContainerCards>
      {photos &&
        photos.map((photo, index) => {
          return (
            <motion.div className="photo_galerie" key={`${index}-${photo}`}>
              <img
                onClick={() => setPhotoSelected(index)}
                src={`${IMAGE_BASE_URL}/images/miniatures/${photo}`}
              />
            </motion.div>
          );
        })}
      {photoSelected !== null && (
        <Backdrop onClick={handleCloseBackdrop}>
          <CarrouselPhotos props={{ photos, currentPhoto: photoSelected }} />
        </Backdrop>
      )}
    </ContainerCards>
  );
}

export default SpectacleViewPhotos;
