/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/logo/logo.webp';
import './Header.css';
// import TranslateIcon from '@mui/icons-material/Translate';
import EnglishFlagIcon from '../assets/icons/EnglishFlagIcon.svg';
import FranceFlagIcon from '../assets/icons/FranceFlagIcon.svg';
import SpainFlagIcon from '../assets/icons/SpainFlagIcon.svg';

// eslint-disable-next-line space-before-function-paren
function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [t, i18n] = useTranslation('global');
  const [menuOpen, setMenuOpen] = useState(false);
  const [lenguageOpen, setLenguageOpen] = useState(false);
  const [popUp, setPopUp] = useState({ open: false, position: null, text: '' });
  const [flagIcon, setFlagIcon] = useState(FranceFlagIcon);
  const headerRef = useRef(null);
  const handleLenguage = (language) => {
    i18n.changeLanguage(language);
    setLenguageOpen(false);
    if (language === 'en') {
      setFlagIcon(EnglishFlagIcon);
    } else if (language === 'fr') {
      setFlagIcon(FranceFlagIcon);
    } else if (language === 'esp') {
      setFlagIcon(SpainFlagIcon);
    }
  };

  /* useEffect(() => {
    if (location.pathname === '/') {
      setMenuOpen(true);
      setLenguageOpen(true);
      setTimeout(() => setLenguageOpen(false), 1000);
    }
  }, [location]); */

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(e.target) &&
        !e.target.classList.contains('language_icon')
      ) {
        setLenguageOpen(false);
        setMenuOpen(false);
      }
    };

    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        setLenguageOpen(false);
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  /* const showPopUp = (e, text) => {
    const position = { x: e.clientX, y: e.clientY };

    setPopUp({ open: true, position, text });
    setTimeout(() => {
      setPopUp({ open: false, position: null, text: '' });
    }, 1500);
  }; */

  return (
    <header role="banner" ref={headerRef}>
      <div className="container_header">
        <div className="container_logo">
          <div
            className={`language_options ${
              lenguageOpen ? 'lenguage_options_open' : ''
            }`}
          >
            <img
              src={flagIcon}
              alt="España"
              className="language_icon"
              onClick={() => setLenguageOpen(!lenguageOpen)}
            />
            <p
              aria-label="Traducir en español"
              onClick={() => handleLenguage('esp')}
            >
              Es
            </p>
            <p
              aria-label="Traduire en français"
              onClick={() => handleLenguage('fr')}
            >
              Fr
            </p>
            <p
              aria-label="Traduire en anglais"
              onClick={() => handleLenguage('en')}
            >
              En
            </p>
          </div>
          <img
            className="logo_header"
            src={logo}
            alt="lien vers l'accueil"
            aria-label="Accueil"
            onClick={() => navigate('/')}
            // onMouseEnter={(e) => showPopUp(e, t('navMenu.popUpLogo'))}
          />
        </div>
        <nav className={menuOpen ? 'active_nav_menu' : 'hidden_nav_menu'}>
          <ul>
            <li
              className={menuOpen ? 'active_menu_item_0' : 'hidden_menu_items'}
            >
              <Link onClick={() => setMenuOpen(!menuOpen)} to="/LaCompagnie">
                {t('navMenu.laCompagnie')}
              </Link>
            </li>
            <li
              className={menuOpen ? 'active_menu_item_1' : 'hidden_menu_items'}
            >
              <Link onClick={() => setMenuOpen(!menuOpen)} to="/Spectacles">
                {t('navMenu.spectacles')}
              </Link>
            </li>
            <li
              className={menuOpen ? 'active_menu_item_2' : 'hidden_menu_items'}
            >
              <Link onClick={() => setMenuOpen(!menuOpen)} to="/Agenda">
                {t('navMenu.agenda')}
              </Link>
            </li>
            <li
              className={menuOpen ? 'active_menu_item_3' : 'hidden_menu_items'}
            >
              <a
                onClick={() => setMenuOpen(!menuOpen)}
                href="https://www.helloasso.com/associations/association-acquaforte-theatre/formulaires/1"
                target="_blank"
              >
                {t('navMenu.don')}
              </a>
            </li>
            <li
              className={menuOpen ? 'active_menu_item_4' : 'hidden_menu_items'}
            >
              <Link onClick={() => setMenuOpen(!menuOpen)} to="/Contact">
                {t('navMenu.contact')}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div
        onClick={() => setMenuOpen(!menuOpen)}
        className="bars_menu"
        id="bars_menu"
        aria-label="menu"
      >
        <span
          className={menuOpen ? 'activeline1_bars_menu' : 'line1_bars_menu'}
        ></span>
        <span
          className={menuOpen ? 'activeline2_bars_menu' : 'line2_bars_menu'}
        ></span>
        <span
          className={menuOpen ? 'activeline3_bars_menu' : 'line3_bars_menu'}
        ></span>
      </div>
      {/* {popUp.open && <PopUp text={popUp.text} position={popUp.position} />} */}
    </header>
  );
}

export default Header;
