/* eslint-disable no-undef */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import { jwtDecode } from 'jwt-decode';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { queryUser } from '../services/appServices';

import '../components/ContactForm.css';

function Conexion() {
  const navigate = useNavigate();
  const handleResponse = async (googleResponse) => {
    const userObject = jwtDecode(googleResponse.credential);
    const response = await queryUser({ email: userObject.email });
    console.log({ response });
    if (response.isAuth) {
      localStorage.setItem('isAuthenticated', response.isAuth);
      localStorage.setItem('user', googleResponse.credential);
      navigate('/Admin');
    } else {
      window.alert('No autorizado');
    }
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id:
        '613778346271-mvf83o1grj00tn2c09dqftj3ktl7bgcm.apps.googleusercontent.com',
      callback: handleResponse,
    });

    google.accounts.id.renderButton(document.getElementById('logInButton'), {
      theme: 'outline',
      size: 'large',
    });
  }, []);

  return (
    <div className="content_page_container pt-52">
      <h1>Acquaforte Theatre</h1>
      <h2>Conexion</h2>
      <div id="logInButton"></div>
    </div>
  );
}

export default Conexion;

// ID cliente 0auth 613778346271-mvf83o1grj00tn2c09dqftj3ktl7bgcm.apps.googleusercontent.com
// SECRETO cliente 0auth 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
// codigo dado para inici de sesion con facundo.botta.dev@gmail.com : "4/0AeaYSHD55IoeqzZUBaLcidtxK83L3QmvgtBTrJs2qmnyge7YE7O78D5mazlpY7HC0-5qoA"
// codigo dado para inici de sesion con nutuestampados@gmail.com : 4/0AeaYSHAzgbotMbfLLL6I11H3tCsJ4ZAkjhsaWQ5FNac2Yvu3x21q6i4zmFBIon6QJKtEyA
