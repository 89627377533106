/* eslint-disable no-unused-vars */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMAGE_BASE_URL } from '../confing';
import { capitalize } from '../Utils/Helpers';
import './MembreEquipeCard.css';

function MembreEquipeCard({ membre, delay }) {
  const { id, background, name, lastname, role, description } = membre;
  const [selectedCard, setSelectedCard] = useState(null);
  const [t, i18n] = useTranslation('global');
  const currentLanguage = t(i18n.language);
  const [isHovered, setIsHovered] = useState(false);

  let statiqueBackground = ''; // Usamos 'let' para permitir la reasignación

  if (background && background.includes('.gif')) {
    statiqueBackground = background.replace('.gif', '.webp'); // Reemplazamos .gif por .webp
  }

  console.log(statiqueBackground);

  const toggleCard = (id = null) => {
    if (id) {
      setSelectedCard(id);
      document.body.classList.add('no_scroll');
    } else {
      setSelectedCard(null);
      document.body.classList.remove('no_scroll');
    }
  };
  /* 
  const itemVariants = {
    hidden: { y: '100vh', opacity: 0 },
    visible: {
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.08,
        type: 'spring',
        damping: 25,
        stiffness: 500,
        delay: delay / 10,
      },
    },
  }; 
  const cvModalVariants = {
    hidden: {
      y: '-100vh',
      rotate: 20,
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
    visible: {
      opacity: 1,
      y: '0',
      rotate: 0,
      transition: {
        duration: 0.1,
        type: 'spring',
        damping: 25,
        stiffness: 500,
        when: 'beforeChildren',
        staggerChildren: 0.5,
        delayChildren: -0.2,
      },
    },
    exit: {
      y: '-100vh',
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  };
  const cvContentVariants = {
    visible: {
      opacity: 1,
      transition: { duration: 1 },
    },
    hidden: {
      opacity: 0,
      transition: { duration: 1 },
    },
  };
*/
  const itemVariants = {
    hidden: { y: '100vh', opacity: 0 },
    visible: {
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.08,
        type: 'spring',
        damping: 25,
        stiffness: 500,
        delay: delay / 10,
      },
    },
  };

  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.9,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: 'easeOut',
      },
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: {
        duration: 0.2,
        ease: 'easeIn',
      },
    },
  };

  return (
    <>
      <motion.div
        className="person_card"
        onClick={() => toggleCard(id)}
        variants={itemVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="block_person_card">
          {!statiqueBackground && statiqueBackground !== '' ? (
            <div
              className="person_card_image"
              alt={`image du membre ${name}`}
              style={{
                backgroundImage: isHovered
                  ? `url(${IMAGE_BASE_URL}/images/membres/${background})`
                  : `url(${IMAGE_BASE_URL}/images/membres/${statiqueBackground})`,
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            ></div>
          ) : (
            <div
              className="person_card_image"
              alt={`image du membre ${name}`}
              style={{
                backgroundImage: `url(${IMAGE_BASE_URL}/images/membres/${background})`,
              }}
            ></div>
          )}
        </div>
        <div className="">
          <h3>
            {capitalize(name)} {capitalize(lastname)}
          </h3>
        </div>
      </motion.div>
      <AnimatePresence>
        {selectedCard && (
          <div
            className="fixed inset-0 z-[7000] overflow-y-auto bg-black/90 3xl:pt-32"
            onClick={(e) => {
              if (e.target === e.currentTarget) toggleCard(null);
            }}
          >
            <div className="min-h-full lg:px-4 py-8">
              <div className="flex items-center justify-center">
                <motion.div
                  className="relative w-full max-w-3xl rounded-xl  p-2 lg:p-6"
                  variants={modalVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <motion.span
                    className="absolute top-0 right-0 lg:top-10 lg:right-10 cursor-pointer"
                    onClick={() => toggleCard(null)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                  >
                    <CloseOutlinedIcon />
                  </motion.span>

                  <div className="flex flex-col items-center w-full">
                    <div
                      className="h-48 w-48 rounded-full bg-cover bg-center mb-6 transition-all duration-300"
                      style={{
                        backgroundImage: `url(${IMAGE_BASE_URL}/images/membres/${background})`,
                        backgroundPosition: isHovered ? '0 0' : 'top left', // Cambia para simular "pausa"
                      }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    />
                    <div className="bg-[#292929] p-4 rounded-lg">
                      <h2 className="text-2xl font-bold text-white mb-2">
                        {capitalize(name)} {capitalize(lastname)}
                      </h2>

                      <p className="text-gray-400 mb-6">{capitalize(role)}</p>

                      <div className="prose prose-invert max-w-none">
                        <p className="text-gray-300 leading-relaxed">
                          {capitalize(description[currentLanguage])}
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
}

export default MembreEquipeCard;

/* 
return (
    <>
      <motion.div
        className="person_card"
        onClick={() => setSelectedCard(id)}
        variants={itemVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="block_person_card">
          <div
            className="person_card_image"
            alt={image du membre ${name}}
            style={{
              backgroundImage: url(${IMAGE_BASE_URL}/images/membres/${background}),
            }}
          ></div>
        </div>
        <div className="">
          <h3>
            {capitalize(name)} {capitalize(lastname)}
          </h3>
        </div>
      </motion.div>
      {selectedCard && (
        <Backdrop
          className="fixed top-0 left-0 right-0 bottom-0 z-[7000] overflow-y-auto bg-black bg-opacity-90 flex justify-center"
          onClick={() => setSelectedCard(null)}
        >
          <motion.div
            className="text-center flex flex-col justify-center items-center self-center w-full max-w-[1000px]"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={cvModalVariants}
          >
            <motion.span
              className="absolute top-0 right-0 cursor-pointer"
              onClick={() => setSelectedCard(null)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 8 }}
            >
              <CloseOutlinedIcon />
            </motion.span>
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={cvModalVariants}
              className="bg-cover bg-center bg-no-repeat w-[200px] h-[200px] rounded-full mb-4"
              alt={image du membre ${name}}
              style={{
                backgroundImage: url(${IMAGE_BASE_URL}/images/membres/${background}),
              }}
            ></motion.div>
            <motion.div className="rounded-lg p-4 bg-slate-800">
              <motion.div variants={cvContentVariants}>
                <h2 variants={cvContentVariants}>
                  {capitalize(name)} {capitalize(lastname)}
                </h2>
                <p className="font-thin mb-5" variants={cvContentVariants}>
                  {capitalize(role)}
                </p>
              </motion.div>
              <motion.p variants={cvContentVariants}>
                {capitalize(description[currentLanguage])}
              </motion.p>
            </motion.div>
          </motion.div>
        </Backdrop>
      )}
    </>
  )
*/
