/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React from 'react';
import { motion } from 'framer-motion';
function SpectacleViewVideo ({ video }) {
  // The regular expression to extract the video ID
  const videoId = video?.match(
    /(?:\?v=|\/embed\/|\/\d\/|\/vi\/|youtu\.be\/|\/embed\/|\/v\/|\/e\/|youtu\.be\/|\/v=)([^#\&\?]*).*/
  )[1];
  const url = `https://www.youtube.com/embed/${videoId}`;

  return (
        <motion.div className="spectacle_video container_cards" >
            <iframe src={url} title="YouTube video player"></iframe>
        </motion.div>
  );
}

export default SpectacleViewVideo;
