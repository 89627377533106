/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import React from 'react';
import './Admin.css';
import AdminFormInsertSpectacle from './AdminFormInsertSpectacle';
import AdminFormInsertAgenda from './AdminFormInsertAgenda';
import AdminFormInsertMembre from './AdminFormInsertMembre';
import AdminFormCollaborateurs from './AdminFormCollaborateurs';
import { AdminInsertPost } from './AdminInsertPost';

function AdminFormInsertContainer ({ column, handleBackdrop, itemToUpdate }) {
  return (
		<div className='admin_form_insert'>
			{
			column === 'spectacles' && (
					<AdminFormInsertSpectacle itemToUpdate={itemToUpdate} handleBackdrop={handleBackdrop} />
			)
			}
			{
				column === 'agenda' && (
					<AdminFormInsertAgenda itemToUpdate={itemToUpdate} handleBackdrop={handleBackdrop} />
				)
			}
			{
				column === 'membres' && (
					<AdminFormInsertMembre itemToUpdate={itemToUpdate} handleBackdrop={handleBackdrop} />
				)
			}
			{
				column === 'collaborateurs' && (
					<AdminFormCollaborateurs itemToUpdate={itemToUpdate} handleBackdrop={handleBackdrop} />
				)
			}
			{
				column === 'posts' && (
					<AdminInsertPost itemToUpdate={itemToUpdate} handleBackdrop={handleBackdrop} />
				)
			}
		</div>
  );
}

export default AdminFormInsertContainer;
