import '../components/ErrorPage.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function ErrorPage ({ error }) {
  console.log(error);
  const navigate = useNavigate();
  const [t] = useTranslation('pages');
  // const location = useLocation();
  // const error = location.state?.error || 'Unknown error';
  return (
      <div className="main_container">
        <section className="error_page_section">
          <h1>{t(`errorPage.h1.${error}`)}</h1>
          <p>{t(`errorPage.p1.${error}`)}</p>
          <p>{t(`errorPage.p2.${error}`)}</p>
          <button className="btn_back_to_home" onClick={() => navigate('/')}>{t('errorPage.btn-home')}</button>
        </section>
      </div>
  );
}
