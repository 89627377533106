/* eslint-disable no-unused-vars */
import { motion } from 'framer-motion';
import React from 'react';
import { IMAGE_BASE_URL } from '../confing';
import './SpectacleView.css';

function SpectacleCard({ spectacle, delay, onClick }) {
  const itemVariants = {
    hidden: { y: '100vh', opacity: 0 },
    visible: {
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.08,
        type: 'spring',
        damping: 25,
        stiffness: 500,
        delay: delay / 10,
      },
    },
  };

  return (
    <motion.article
      className="spectacle_card"
      style={{
        backgroundImage: `url(${IMAGE_BASE_URL}/images/miniatures/${spectacle.background})`,
      }}
      alt={`Affiche du évènement ${spectacle.title}`}
      variants={itemVariants}
      initial="hidden"
      animate="visible"
      onClick={onClick}
    >
      <img
        src={`${IMAGE_BASE_URL}/images/spectacles/${spectacle.background}`}
      />
    </motion.article>
  );
}

export default SpectacleCard;
