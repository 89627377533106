import { jwtDecode } from 'jwt-decode';

export function capitalize(text) {
  if (typeof text !== 'string' || text.trim().length === 0) {
    return;
  }
  const words = text.trim().split(' ');
  if (words.length === 0) {
    return;
  }
  const firstWord = words[0];
  if (firstWord.length > 0) {
    words[0] = firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
  }
  return words.join(' ');
}

export function parseDate(date) {
  const dateTime = new Date(date);
  // Format date
  const dateFormat = dateTime.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
  // Format hours
  const heuresFormat = dateTime
    .toLocaleTimeString('fr-FR', {
      hour: 'numeric',
      minute: 'numeric',
    })
    .replace(':', 'h');
  return { dateFormat, heuresFormat };
}
export function isTokenExpired(token) {
  try {
    const { exp, ...user } = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (!user || exp < currentTime) {
      return null;
    }

    return user;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
}
