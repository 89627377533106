/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import logoWhite from '../assets/logo/logo-white.svg';
import MetaData from './MetaData';
function MainLayaut({
  children,
  background,
  meta = { title: '', description: '' },
}) {
  const [t] = useTranslation('pages');
  const location = useLocation();
  const variants = {
    hidden: {
      x: '100vw',
      transition: {
        when: 'afterChildren',
      },
    },
    visible: {
      x: '0',
      transition: {
        duration: 0.5,
        type: 'spring',
        damping: 25,
        stiffness: 500,
        when: 'beforeChildren',
        staggerChildren: 0.3,
        delayChildren: 0.2,
      },
    },
    exit: {
      x: '-100vw',
      transition: {
        when: 'afterChildren',
      },
    },
  };

  return (
    <motion.main
      className="main_container scroll-smooth"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <MetaData title={meta.title} description={meta.description} />
      {location.pathname !== '/LaCompagnie' && (
        <section className="head_page_section">
          <img
            className="logo"
            src={logoWhite}
            alt="logo compagnie acquaforte"
          />
          {location.pathname === '/Contact' && (
            <motion.h2
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={variants}
            >
              {t(`${location.pathname}.h2-first`)}
            </motion.h2>
          )}
        </section>
      )}
      <motion.section className="content_page_container">
        {children}
      </motion.section>
    </motion.main>
  );
}

export default MainLayaut;
