/* eslint-disable no-unused-vars */
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useState } from 'react';
import '../components/Admin.css';
import { IMAGE_BASE_URL } from '../confing';
import { capitalize } from '../Utils/Helpers';
import { AdminFormDelete } from './AdminFormDelete';
import AdminFormInsertContainer from './AdminFormInsertContainer';
import Backdrop from './Backdrop';

const AdminColumnItems = ({ title, items }) => {
  const [selectedItem, setSelectedItem] = useState({ form: null, item: null });
  const [backDropOpen, setBackDropOpen] = useState(null);
  const handleBackdrop = () => {
    setBackDropOpen(!backDropOpen);
  };
  console.log(items);
  return (
    <>
      <h2>{capitalize(title)}</h2>
      <div className="container_cards column_container_items">
        <div
          className="item_box add_item"
          onClick={() => {
            setBackDropOpen(true);
            setSelectedItem({ form: 'insert', item: null });
          }}
        >
          <AddOutlinedIcon />
        </div>
        {items ? (
          items.map((item, index) => (
            <div
              key={index}
              className="item_box"
              style={{
                backgroundImage: `url(${IMAGE_BASE_URL}/images/${title}/${item.background})`,
              }}
              alt={`Affiche du évènement ${item.title}`}
            >
              <div key={index} className="hidden_div">
                <div
                  className="delete_div"
                  onClick={() => {
                    setBackDropOpen(true);
                    setSelectedItem({ form: 'delete', item });
                  }}
                >
                  <DeleteOutlinedIcon />
                </div>
                <div
                  className="update_div"
                  onClick={() => {
                    setBackDropOpen(true);
                    setSelectedItem({ form: 'update', item });
                  }}
                >
                  <EditOutlinedIcon />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div> Nada para mostrar! </div>
        )}
        {backDropOpen && (
          <Backdrop onClick={handleBackdrop}>
            {selectedItem.form === 'delete' && (
              <AdminFormDelete
                column={title}
                item={selectedItem.item}
                handleBackdrop={handleBackdrop}
              />
            )}
            {(selectedItem.form === 'insert') |
              (selectedItem.form === 'update') && (
              <AdminFormInsertContainer
                column={title}
                handleBackdrop={handleBackdrop}
                itemToUpdate={selectedItem.item}
              />
            )}
          </Backdrop>
        )}
      </div>
    </>
  );
};
export default AdminColumnItems;
