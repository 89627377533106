import React from 'react';
import { useTranslation } from 'react-i18next';
import ContainerCards from './ContainerCards';

function SpectacleViewDescription({ spectacle }) {
  const [t, i18n] = useTranslation('pages');
  const currentLenguage = t(i18n.language);

  const { infos } = spectacle;
  const { description } = spectacle;
  // console.log(spectacle);

  if (infos) {
    const renderedInfos = Object.keys(infos).map((key) => {
      const info = infos[key];
      return (
        <p key={key}>
          <span>{info[`titre_info_${currentLenguage}`]}: </span>{' '}
          {info.contenue_info}
        </p>
      );
    });
  }

  return (
    <ContainerCards>
      <div className="spectacle_description">
        <h2>SYNOPSIS</h2>
        <p className="description_spectacle">{description[currentLenguage]}</p>
        <div className="ln_bar_separator" />
        <div className="infos_ln">
          {infos &&
            infos.map((info, index) => (
              <p key={index}>
                <span>{info.titre[currentLenguage]}: </span>{' '}
                {info.value[currentLenguage]}
              </p>
            ))}
        </div>
      </div>
    </ContainerCards>
  );
}

export default SpectacleViewDescription;
