/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { motion, scroll } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useGetData } from '../hooks/useGetData';
import { IMAGE_BASE_URL } from '../confing';
import Description from '../components/Description';
import MainLayaut from '../components/MainLayaut';
import MembreEquipeCard from '../components/MembreEquipeCard';

import slideBtn from '../assets/icons/slide-btn.svg';

function LaCompagnie() {
  const background = `images/fonds/LaCompagnie.webp`;
  const { data: membres } = useGetData({ column: 'membres' });
  const { data: collaborateurs } = useGetData({ column: 'collaborateurs' });
  const [t] = useTranslation('pages');
  const handlePageUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [scrollOpacity, setScrollOpacity] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const opacity = Math.min(scrollPosition / 500, 1);
      setScrollOpacity(opacity);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <MainLayaut
      background={background}
      meta={{
        title: 'Acquaforte Qui Sommes Nous',
        description:
          "Explorez l'univers captivant d'Acquaforte Theatre à Montpellier et découvrez notre équipe passionnée d'artistes talentueux. Plongez dans l'histoire et la mission de notre compagnie de théâtre, et rencontrez les membres qui donnent vie à nos spectacles inoubliables. Joignez-vous à nous pour une expérience théâtrale riche en émotions et en créativité.",
      }}
    >
      <Description opacity={scrollOpacity} />
      <section
        id="membres-section"
        className="min-h-[100vh] max-w-[1200px] flex flex-col justify-evenly items-center"
      >
        <section className="text-center">
          <h2 className="mb-5">{t('/LaCompagnie.h2-first')}</h2>
          <div className="flex flex-wrap justify-center items-center gap-4">
            {membres ? (
              membres.map((membre, index) => (
                <MembreEquipeCard
                  membre={membre}
                  key={membre.id}
                  delay={index}
                />
              ))
            ) : (
              <div>Aucune membre dans l’équipe pour le moment</div>
            )}
          </div>
        </section>

        <section className="text-center">
          <h2 className="mb-5">{t('/LaCompagnie.h2-second')}</h2>
          <div className="flex flex-wrap justify-center items-center gap-12">
            {collaborateurs ? (
              collaborateurs.map((collaborateur) => (
                <a
                  key={collaborateur.id}
                  href={collaborateur.link}
                  target="_blank"
                >
                  <img
                    className="h-[7rem] w-auto border-black border-2 border-solid hover:scale-105 transition-all ease-in-out duration-300"
                    alt={`imagen de notre partenaire ${collaborateur.name}`}
                    src={`${IMAGE_BASE_URL}/images/collaborateurs/${collaborateur.background}`}
                  />
                </a>
              ))
            ) : (
              <p>Aucune collaborateur pour le moment</p>
            )}
          </div>
        </section>
      </section>
      <img
        onClick={() => handlePageUp()}
        className="btn_slide"
        src={slideBtn}
        alt="bouton up to top"
      />
    </MainLayaut>
  );
}

export default LaCompagnie;
