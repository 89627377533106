/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Helmet } from 'react-helmet-async';

function MetaData ({ title, description }) {
  return (
		<Helmet>
			{ /* Standard metadata tags */ }
			<title>{title}</title>
			<link rel='canonical' href={ window.location.href } />
			<meta name='description' content={`Acquaforte théâtre | ${description}`} />
			{ /* End standard metadata tags */ }
			{ /* Facebook tags */ }
			<meta property="og:url" content={window.location.href} />
			<meta property="og:type" content="website" />
			<meta property="og:title" content="Acquaforte théâtre" />
			<meta property="og:description" content={`${title} | ${description}`} />
			{/* image tag */}
			<meta property="og:image" content="https://st.depositphotos.com/2656329/4008/v/450/depositphotos_40089595-stock-illustration-theater-stage-vector-illustration.jpg" />
			<meta property="og:image:secure_url" content="https://st.depositphotos.com/2656329/4008/v/450/depositphotos_40089595-stock-illustration-theater-stage-vector-illustration.jpg" />
			<meta property="og:image:type" content="image/jpeg" />
			{/* <meta property="og:image" content="https://example.com/path/to/your/image.jpg" /> */}
			{/* <meta property="og:image:secure_url" content="https://example.com/path/to/your/image.jpg" /> */}
			{/* <meta property="og:image:type" content="image/svg+xml" /> */}
			<meta property="og:image:width" content="200" />
			<meta property="og:image:alt" content="The acquaforte's image!" />
			{ /* End Facebook tags */ }
			{ /* Twitter tags */ }
			<meta name="twitter:creator" content="Acquaforte" />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content="Acquaforte théâtre" />
			<meta name="twitter:description" content={`${title} | ${description}`} />
			{ /* End Twitter tags */ }
		</Helmet>
  );
}

export default MetaData;
