/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IMAGE_BASE_URL } from '../confing';
import { useGetData } from '../hooks/useGetData';
import { insertEvent, updateEvent } from '../services/appServices';
import './Admin.css';

function AdminFormInsertAgenda({ itemToUpdate, handleBackdrop }) {
  console.log(itemToUpdate);
  const { data: spectacles } = useGetData({ column: 'spectacles' });
  const [spectacleAsocie, setSpectacleAsocie] = useState(null);
  const [imageDrop, setImageDrop] = useState('');

  // This useEffect take the spectacle atach to the event if its exist
  useEffect(() => {
    if (itemToUpdate !== null && itemToUpdate.id_spectacle) {
      setImageDrop(itemToUpdate.background);
      const spectacle = spectacles.filter(
        (spectacle) => spectacle.id === itemToUpdate.id_spectacle
      );
      setSpectacleAsocie(spectacle[0]);
    }
  }, [itemToUpdate, spectacles]);
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: {
        'image/*': [],
      },
      onDrop: (acceptedFiles) => {
        setImageDrop(acceptedFiles[0]);
      },
    });
  useEffect(() => {
    handleBackgroundDrop();
  }, [imageDrop]);

  const handleBackgroundDrop = () => {
    if (itemToUpdate && !acceptedFiles[0]) {
      return `url(${IMAGE_BASE_URL}/images/agenda/${itemToUpdate.background})`;
    } else if (acceptedFiles[0]) {
      return `url(${URL.createObjectURL(imageDrop)})`;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formValid = true;
    const formData = new FormData(e.target);
    if (acceptedFiles) {
      formData.set('image', acceptedFiles[0]);
    }
    // este bucle checkea si todos los campos del form estan completos
    for (const [key, value] of formData.entries()) {
      if (value === '' || value === 'undifined') {
        e.target.elements[key].classList.add('invalid_field');
        formValid = false;
      }
    }

    if (formValid) {
      if (itemToUpdate === null) {
        try {
          // insert query function
          const response = await insertEvent(formData);
          if (response.error) {
            window.alert(response.error);
            console.log(response);
          } else {
            window.alert('Evento agregado correctamente');
            window.location.reload();
          }
        } catch (error) {
          console.error('Error al agregar el evento:', error);
          window.alert(
            'Error al agregar el evento. Por favor, inténtalo de nuevo más tarde.'
          );
        }
      } else {
        // tomando los elementos que cambiaron del evento
        const newData = new FormData();
        newData.set('eventId', itemToUpdate.id);
        newData.set('oldImage', itemToUpdate.background);
        formData.forEach((value, key) => {
          if (itemToUpdate[key] !== value && value !== 'undefined') {
            newData.set(key, value);
          }
        });
        // update query function
        try {
          const response = await updateEvent({ newData });
          if (response.error) {
            window.alert(response.error);
            console.log(response);
          } else {
            window.alert('Evento Actualizado correctamente');
            window.location.reload();
          }
        } catch (error) {}
      }
    } else {
      window.alert('Ramplisez tout les champs');
    }
  };

  return (
    <div>
      <h3>
        {itemToUpdate != null ? 'Actualiser évènement' : 'Nouvel évènement'}
      </h3>
      <form className="admin_form" onSubmit={handleSubmit}>
        <div
          {...getRootProps()}
          className="form_drop_box"
          style={{
            backgroundColor: isDragActive ? '#3e3e3ec7' : 'none',
            backgroundImage: handleBackgroundDrop(),
            outline: acceptedFiles[0] ? 'none' : 'white 2px dashed',
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>{acceptedFiles[0] ? '' : 'Suelta la imagen aqui...'} </p>
          ) : (
            <p>
              {acceptedFiles[0]
                ? ''
                : 'Arrastra aqui la imagen o haz click aqui...'}
            </p>
          )}
        </div>
        <label>
          Title *
          <input
            name="title"
            type="text"
            placeholder="Nom du évènement"
            defaultValue={itemToUpdate !== null ? itemToUpdate.title : ''}
          />
        </label>
        <label>
          Lieu *
          <input
            name="place"
            type="text"
            placeholder="Nom du lieu"
            defaultValue={itemToUpdate !== null ? itemToUpdate.place : ''}
          />
        </label>
        <label>
          Date *
          <input
            name="date"
            type="datetime-local"
            placeholder="Date"
            defaultValue={
              itemToUpdate !== null ? itemToUpdate.date.slice(0, 16) : ''
            }
          />
        </label>
        <label>
          Adresse *
          <input
            name="adresse"
            type="text"
            placeholder="Adresse"
            defaultValue={itemToUpdate !== null ? itemToUpdate.adresse : ''}
          />
        </label>
        <label>
          Link Google Maps *
          <input
            name="google_link"
            type="text"
            placeholder="Link Google Maps"
            defaultValue={itemToUpdate !== null ? itemToUpdate.google_link : ''}
          />
        </label>
        <label>
          Spectacle associé <br />
          <select
            name="id_spectacle"
            defaultValue={spectacleAsocie ? spectacleAsocie.id : null}
          >
            <option value={'null'}>Pas d'event associé</option>
            {spectacleAsocie && (
              <option value={spectacleAsocie.id}>
                {spectacleAsocie.title}
              </option>
            )}
            {spectacles && spectacles.length > 0 ? (
              spectacles.map((spectacle) => (
                <option key={spectacle.id} value={spectacle.id}>
                  {spectacle.title}
                </option>
              ))
            ) : (
              <option disabled>Aucun événement disponible</option>
            )}
          </select>
        </label>
        <button>Confirmer</button>
        <button onClick={handleBackdrop}>Cancelar</button>
      </form>
    </div>
  );
}

export default AdminFormInsertAgenda;
