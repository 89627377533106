import React, { useEffect, useRef } from 'react';
import videoFond from '../assets/POPURRI DE ACQUA.mp4';
import MetaData from '../components/MetaData';

function Home() {
  const videoRef = useRef(null);

  const metaData = {
    title: 'Acquaforte Theatre Accueil',
    description:
      "Découvrez le théâtre à Montpellier avec Acquaforte Theatre. Explorez notre répertoire, achetez des billets et restez informé des dernières actualités théâtrales. Laissez-vous inspirer par l'art du théâtre.",
    image: 'default',
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error('Autoplay bloqueado en Safari:', error);
      });
    }
  }, []);

  return (
    <>
      <MetaData
        title={metaData.title}
        description={metaData.description}
        image={metaData.image}
      />
      <section className="container_video">
        <video
          ref={videoRef}
          src={videoFond}
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover"
        ></video>
      </section>
    </>
  );
}

export default Home;
