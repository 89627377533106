import { motion } from 'framer-motion';
import React, { useEffect } from 'react';

const Backdrop = ({ children, onClick, className = null }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClick();
      }
    };

    window.addEventListener('keydown', handleEscape);
    document.body.classList.add('no_scroll');

    return () => {
      window.removeEventListener('keydown', handleEscape);
      document.body.classList.remove('no_scroll');
    };
  }, [onClick]);

  const handleClick = (e) => {
    if (e.target === e.currentTarget) {
      onClick();
    }
  };

  return (
    <motion.div
      className={`${className ? className : 'backdrop'}`}
      onClick={handleClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default Backdrop;
