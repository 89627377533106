/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Backdrop from '../components/Backdrop';
import '../components/ContactForm.css';
import MainLayaut from '../components/MainLayaut';
import TextArea from '../components/TextArea';
import { sendEmail } from '../services/appServices';
import okIcon from '../assets/icons/happy-face-icon.svg';
import errorIcon from '../assets/icons/error-icon.svg';

function Contact() {
  const [t] = useTranslation('pages');
  const background = 'images/fonds/Contact.webp';
  const textAreaRef = useRef(null);
  const formRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [messagesSubmit, setMessagesSubmit] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageSendedImage, setMessageSendedImage] = useState(null);

  const itemVariants = {
    hidden: { y: '100vh', opacity: 0 },
    visible: {
      y: '0',
      opacity: 1,
      transition: {
        duration: 0.08,
        type: 'spring',
        damping: 25,
        stiffness: 500,
      },
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let message = [];
    let formValid = true;

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      subject: e.target.subject.value,
      message: textAreaRef.current.value,
    };

    // Validación de campos vacíos
    for (const [key, value] of Object.entries(formData)) {
      if (value === '') {
        message.push('\nRemplissez tous les champs');
        formValid = false;
        break;
      }
    }

    // Validación de longitud del mensaje
    if (formData.message.length > 300) {
      message.push(' \n300 caractères max pour le message');
      formValid = false;
      textAreaRef.current.classList.add('invalid_field');
    } else {
      textAreaRef.current.classList.remove('invalid_field');
    }

    if (formValid) {
      try {
        const response = await sendEmail(formData);

        if (response.ok) {
          // Caso de éxito
          message = t('/Contact.form.submit.ok');
          setMessageSendedImage(okIcon);
        } else if (response.status === 429) {
          // Error por límite de solicitudes
          message = t('/Contact.form.submit.error-429');
          setMessageSendedImage(errorIcon);
        } else if (response.status === 400) {
          // Error de datos inválidos
          const errorData = await response.json();
          if (errorData.error === 'invalid-data') {
            message = t('/Contact.form.submit.error-invalid-data');
            setMessageSendedImage(errorIcon);
          } else {
            message = t('/Contact.form.submit.error');
            setMessageSendedImage(errorIcon);
          }
        } else {
          // Otros errores
          message = t('/Contact.form.submit.error');
          setMessageSendedImage(errorIcon);
        }
      } catch (error) {
        // console.error('Error en la solicitud:', error);
        message = t('/Contact.form.submit.error');
        setMessageSendedImage(errorIcon);
      } finally {
        setIsSubmitting(false);
        setModalOpen(true);
        setMessagesSubmit(message);
      }
    } else {
      // Caso de formulario no válido
      setIsSubmitting(false);
      setModalOpen(true);
      setMessagesSubmit(message);
      setMessageSendedImage(errorIcon);
    }

    // Reiniciar el formulario
    formRef.current.reset();
    textAreaRef.current.value = '';
  };

  return (
    <MainLayaut
      background={background}
      meta={{
        title: 'Acquaforte Contact',
        description:
          "Contactez-nous pour découvrir l'univers théâtral de Montpellier avec Acquaforte Theatre. Nous sommes là pour répondre à vos questions et vous fournir toutes les informations dont vous avez besoin sur nos spectacles et événements. N'hésitez pas à nous contacter pour une expérience théâtrale inoubliable.",
      }}
    >
      <div className="container_form">
        <motion.form
          ref={formRef}
          className="form_contact"
          onSubmit={handleSubmit}
          variants={itemVariants}
          initial="hidden"
          animate="visible"
        >
          <input
            name="name"
            type="text"
            placeholder={t('/Contact.form.input-name-placeholder')}
          />
          <input
            name="email"
            type="email"
            placeholder={t('/Contact.form.input-mail-placeholder')}
          />
          <input
            name="subject"
            type="text"
            placeholder={t('/Contact.form.input-subject-placeholder')}
          />
          <TextArea
            ref={textAreaRef}
            placeholder={t('/Contact.form.textarea-placeholder')}
            minHeight={'200px'}
          />
          <button
            style={{ width: '100%' }}
            type="submit"
            disabled={isSubmitting}
          >
            {t('/Contact.form.button-submit')}
          </button>
        </motion.form>
      </div>
      {modalOpen && (
        <Backdrop onClick={() => setModalOpen(false)}>
          <div className="contact_alert">
            <div className="flex w-full h-full justify-center items-center">
              <img src={messageSendedImage} alt="message-sended" />
              <span
                onClick={() => {
                  setModalOpen(false);
                  setMessageSendedImage(null);
                }}
              >
                <CloseOutlinedIcon />
              </span>
              {messagesSubmit && <p className="ml-2">{messagesSubmit}</p>}
            </div>
          </div>
        </Backdrop>
      )}
    </MainLayaut>
  );
}

export default Contact;
