/* eslint-disable no-unused-vars */
import React from 'react';
import { motion } from 'framer-motion';

function ContainerCards ({ children }) {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1
    }
  };

  const itemVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 2
      }
    }
  };

  return (
        <motion.div
            className='container_cards'
            initial='hidden'
            animate='visible'
            variants={containerVariants}
        >
            {children}
            {/* {React.Children.map(children, (child, index) => (
                <motion.div
                    key={index}
                    variants={itemVariants}
                    initial='hidden'
                    animate='visible'
                    transition={{ delay: index * 1 }} // Ajusta el retraso para cada hijo
                >
                    {child}
                </motion.div>
            ))} */}
        </motion.div>
  );
}

export default ContainerCards;
