/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  insertCollaborateur,
  updateCollaborateur,
} from '../services/appServices';
import { IMAGE_BASE_URL } from '../confing';

function AdminFormCollaborateurs({ itemToUpdate, handleBackdrop }) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone();
  const [prevImage, setPrevImage] = useState('');

  useEffect(() => {
    if (itemToUpdate) {
      setPrevImage(itemToUpdate.background);
    }
  }, [itemToUpdate]);

  const backgroundDrop = (index) => {
    if (acceptedFiles[index]) {
      return `url(${URL.createObjectURL(acceptedFiles[index])})`;
    } else if (itemToUpdate !== null) {
      return `url(${IMAGE_BASE_URL}/images/collaborateurs/${prevImage})`;
    } else {
      return '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let formValid = true;
    let message = '';
    if (acceptedFiles[0] || prevImage !== '') {
      if (!itemToUpdate) {
        formData.append('image', acceptedFiles[0]);
      } else if (itemToUpdate && acceptedFiles[0]) {
        formData.append('image', acceptedFiles[0]);
        formData.set('oldImage', prevImage);
      } else if (itemToUpdate && !acceptedFiles[0]) {
        formData.set('oldImage', prevImage);
      }
    } else {
      formValid = false;
      message = 'Il faut une photo!';
    }

    if (formValid) {
      if (!itemToUpdate) {
        try {
          const response = await insertCollaborateur(formData);
          if (response.error) {
            window.alert(response.error);
            console.log(response);
          } else {
            window.alert('Collaborateur ajoute!');
            window.location.reload();
          }
        } catch (error) {
          console.error(error);
          window.alert(
            'Error al agregar al collaborateur. Por favor, inténtalo de nuevo más tarde.'
          );
        }
      } else {
        formData.set('collId', itemToUpdate.id);
        console.log(...formData);
        try {
          const response = await updateCollaborateur(formData);
          if (response.error) {
            window.alert(response.error);
            console.log(response);
          } else {
            window.alert('Collaborateur mis a jour!');
            window.location.reload();
          }
        } catch (error) {
          console.error(error);
          window.alert(
            'Error al actualizar al collaborateur. Por favor, inténtalo de nuevo más tarde.'
          );
        }
      }
    } else {
      window.alert(message);
    }
  };

  return (
    <form className="admin_form" onSubmit={handleSubmit}>
      <div
        {...getRootProps()}
        className="form_drop_box"
        style={{
          backgroundColor: isDragActive ? '#3e3e3ec7' : 'none',
          backgroundImage: backgroundDrop(0),
          outline: acceptedFiles[0] ? 'none' : 'white 2px dashed',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{acceptedFiles[0] ? '' : 'Suelta la imagen aqui...'} </p>
        ) : (
          <p>
            {acceptedFiles[0]
              ? ''
              : 'Arrastra aqui la imagen o haz click aqui...'}
          </p>
        )}
      </div>
      <label>
        Nom du collaborateur *
        <input
          name="name"
          type="text"
          placeholder="Nom du collaborateur"
          defaultValue={itemToUpdate ? itemToUpdate.name : ''}
        />
      </label>
      <label>
        Lien du collaborateur *
        <input
          name="link"
          type="text"
          placeholder="Lien complet du collaborateur"
          defaultValue={itemToUpdate ? itemToUpdate.link : ''}
        />
      </label>
      <button>Confirmer</button>
      <button onClick={handleBackdrop}>Annuler</button>
    </form>
  );
}

export default AdminFormCollaborateurs;
