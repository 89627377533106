import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import routes from '../services/routes';

function AnimatedRoutes() {
  const location = useLocation();
  const isAuthenticated = localStorage.getItem('isAuthenticated'); // Simulación de autenticación

  return (
    <Routes location={location} key={location.pathname}>
      {routes.map(({ path, element, requiresAuth }, index) => {
        if (requiresAuth !== undefined) {
          // Si la ruta requiere autenticación, redirige según corresponda
          return (
            <Route
              key={index}
              path={path}
              element={
                requiresAuth ? (
                  isAuthenticated ? (
                    element
                  ) : (
                    <Navigate to="/Conexion" />
                  )
                ) : !isAuthenticated ? (
                  element
                ) : (
                  <Navigate to="/Admin" />
                )
              }
            />
          );
        }

        // Rutas normales sin autenticación
        return <Route key={index} path={path} element={element} />;
      })}
    </Routes>
  );
}

export default AnimatedRoutes;
