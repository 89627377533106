/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { translateText, updateDescription } from '../services/appServices';
import Backdrop from './Backdrop';
import TextArea from './TextArea';

function DescriptionAdmin({ data }) {
  const textAreaRef = useRef(null);
  const [backDropOpen, setBackDropOpen] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState('fr');
  const [textAreaValues, setTextAreaValues] = useState({});

  useEffect(() => {
    setTextAreaValues({ fr: data.fr, esp: data.esp, en: data.en });
  }, [data]);
  const handleBackdrop = () => {
    setBackDropOpen(!backDropOpen);
  };
  const handleLanguageChange = (e, language) => {
    e.preventDefault();
    const newValues = {
      ...textAreaValues,
      [currentLanguage]: textAreaRef.current.value,
    };
    setTextAreaValues(newValues);
    setCurrentLanguage(language);
    textAreaRef.current.value = textAreaValues[language];
  };
  const handleSubmit = async (allTextAreasValues) => {
    let currentData = {};
    // update all the languages
    if (allTextAreasValues === true) {
      currentData = {
        ...textAreaValues,
        [currentLanguage]: textAreaRef.current.value,
      };
      // or only the current language
    } else {
      currentData = {
        ...data,
        [currentLanguage]: textAreaRef.current.value,
      };
    }
    const response = await updateDescription(currentData);
    if (response.ok) {
      window.alert('Description actualiser correctement');
      window.location.reload();
    } else {
      window.alert('Un erreur est surveille, voulez ressayer');
    }
    handleBackdrop();
  };
  const handleTraduction = async () => {
    const text = textAreaRef.current.value;
    const result = await translateText({
      text,
      languageTarget: currentLanguage,
    });
    if (result.error) {
      window.alert(result.error);
    } else {
      textAreaRef.current.value = result.translation;
    }
  };
  return (
    <div style={{ width: '80%' }}>
      <h2> Description de la compagnie</h2>
      <div className="admin_description_bts">
        <button onClick={(e) => handleLanguageChange(e, 'fr')}>French</button>
        <button onClick={(e) => handleLanguageChange(e, 'esp')}>Spanish</button>
        <button onClick={(e) => handleLanguageChange(e, 'en')}>English</button>
        <button onClick={handleTraduction}>Traduir</button>
        <button onClick={handleBackdrop}>Actualizer</button>
      </div>
      <TextArea
        ref={textAreaRef}
        content={textAreaValues[currentLanguage]}
        width={'100%'}
        placeholder={"I'm a auto-size textarea"}
      />
      {backDropOpen && (
        <Backdrop onClick={handleBackdrop}>
          <div className="admin_form">
            <button onClick={() => handleSubmit(true)}>
              {' '}
              Toutes les Langages{' '}
            </button>
            <button onClick={() => handleSubmit(false)}>
              {' '}
              La langue actuelle{' '}
            </button>
            <button onClick={handleBackdrop}>Annuler</button>
          </div>
        </Backdrop>
      )}
    </div>
  );
}

export default DescriptionAdmin;
