/* eslint-disable no-unused-vars */
// import react from 'react';

// import { DeleteForeverOutlined } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import TextArea from './TextArea';
import { translateText, insertPost, updatePost } from '../services/appServices';
import { useDropzone } from 'react-dropzone';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export function AdminInsertPost({ handleBackdrop, itemToUpdate }) {
  const [titles, setTitles] = useState({ fr: '', esp: '', en: '' });
  const [links, setLinks] = useState({ fr: '', esp: '', en: '', link: '' });
  const [files, setFiles] = useState([]);
  const [affiche, setAffiche] = useState('');
  const [prevImages, setPrevImages] = useState([]);
  // traduction stuff -------------------------------------------------------------
  const textAreaRef = useRef(null);
  const inputTitleRef = useRef(null);
  const linkTitleRef = useRef(null);
  const linkRef = useRef(null);
  const [textAreaValues, setTextAreaValues] = useState({
    fr: '',
    esp: '',
    en: '',
  });
  const [currentLanguage, setCurrentLanguage] = useState('fr');
  const [translationTarget, setTranslationTarget] = useState(null);

  // This useEffect update the data to the form if it's a update case
  useEffect(() => {
    if (itemToUpdate && itemToUpdate.title) {
      const titleValues = {
        fr: itemToUpdate.title.fr,
        esp: itemToUpdate.title.esp,
        en: itemToUpdate.title.en,
      };
      setTitles(titleValues);
      inputTitleRef.current.value = itemToUpdate.title[currentLanguage];
      if (itemToUpdate.link) {
        const linkValues = {
          fr: itemToUpdate.link.fr,
          esp: itemToUpdate.link.esp,
          en: itemToUpdate.link.en,
          link: itemToUpdate.link.link,
        };
        setLinks(linkValues);
        linkRef.current.value = itemToUpdate.link.link;
        linkTitleRef.current.value = itemToUpdate.link.fr;
      }
      if (itemToUpdate.text) {
        const textAreaValues = {
          fr: itemToUpdate.text.fr,
          esp: itemToUpdate.text.esp,
          en: itemToUpdate.text.en,
        };
        setTextAreaValues(textAreaValues);
        textAreaRef.current.value = itemToUpdate.text[currentLanguage];
      }
      if (itemToUpdate.images) {
        setPrevImages(itemToUpdate.images);
        setAffiche(itemToUpdate.images[0]);
      }
    }
  }, [itemToUpdate]);
  // this useEffect update the text to the translate function
  useEffect(() => {
    const handleFocus = () => {
      if (
        document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA'
      ) {
        setTranslationTarget({
          value: document.activeElement.value,
          target: document.activeElement.id,
        });
      }
    };
    document.addEventListener('focusin', handleFocus);
    return () => {
      document.removeEventListener('focusin', handleFocus);
    };
  }, [translationTarget]);
  const handleTraduction = async (e) => {
    e.preventDefault();
    const elementTarget = document.getElementById(translationTarget.target);
    if (translationTarget !== '') {
      const result = await translateText({
        text: translationTarget.value,
        languageTarget: currentLanguage,
      });
      if (result.error) {
        window.alert(result.error);
        console.log(result);
      } else {
        if (elementTarget !== null) {
          elementTarget.value = result.translation;
          console.log(result.translation);
        } else {
          textAreaRef.current.value = result.translation;
        }
      }
    }
  };
  const handleLanguageChange = (e, language) => {
    e.preventDefault();
    const newValuesTextArea = {
      ...textAreaValues,
      [currentLanguage]: textAreaRef.current.value,
    };
    setTextAreaValues(newValuesTextArea);
    const newTitles = {
      ...titles,
      [currentLanguage]: inputTitleRef.current.value,
    };
    setTitles(newTitles);
    const newLinks = {
      ...links,
      [currentLanguage]: linkTitleRef.current.value,
      link: linkRef.current.value,
    };
    setLinks(newLinks);
    setCurrentLanguage(language);
    textAreaRef.current.value = textAreaValues[language];
    inputTitleRef.current.value = titles[language];
    linkTitleRef.current.value = links[language];
    linkRef.current.value = links.link;
  };
  // revoke the data uris to avoid memory leaks, will run on unmount (useDrop documentation)
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: {
        'image/*': [],
      },
      // setting the files state with the files gived in the drop zone
      onDrop: (acceptedFiles) => {
        if (affiche === '') {
          setAffiche(acceptedFiles[0]);
        }
        setFiles((prevFiles) => [
          ...prevFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ]);
      },
    });
  const handleBackgroundDrop = () => {
    if (affiche !== '') {
      if (typeof affiche === 'string') {
        return `url(images/posts/${affiche})`;
      } else {
        return `url(${URL.createObjectURL(affiche)})`;
      }
    } else {
      return 'none';
    }
  };
  // preview of the existents images (update case)
  const prevThumbs = prevImages.map((image, index) => (
    <div className="thumb" key={image}>
      <div className="thumbInner">
        <img
          src={`images/posts/${image}`}
          className="img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(image);
          }}
        />
        <div className="hidden_thumb_delete">
          <DeleteOutlineOutlinedIcon
            onClick={() => handleDeleteImagePreview(index, true)}
          />
          <StarOutlineOutlinedIcon onClick={() => setAffiche(image)} />
        </div>
      </div>
    </div>
  ));
  const thumbs = files.map((file, index) => (
    <div className="thumb" key={file.name}>
      <div className="thumbInner">
        <img
          src={file.preview}
          className="img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <div className="hidden_thumb_delete">
          <DeleteOutlineOutlinedIcon
            onClick={() => handleDeleteImagePreview(index)}
          />
          <StarOutlineOutlinedIcon onClick={() => setAffiche(file)} />
        </div>
      </div>
    </div>
  ));
  const handleDeleteImagePreview = (index, prev = false) => {
    if (prev) {
      const imagesUpdate = prevImages.toSpliced(index, 1);
      setPrevImages(imagesUpdate);
    }
    const filesUpdate = files.toSpliced(index, 1);
    setFiles(filesUpdate);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let message = '';
    let formValid = true;
    // setting the description current values
    const currentTextAreaValues = {
      ...textAreaValues,
      [currentLanguage]: textAreaRef.current.value,
    };
    // setting the title current values
    setTextAreaValues(currentTextAreaValues);
    const currentTitles = {
      ...titles,
      [currentLanguage]: inputTitleRef.current.value,
    };
    setTitles(currentTitles);
    const currentLinks = {
      ...links,
      [currentLanguage]: linkTitleRef.current.value,
      link: linkRef.current.value,
    };
    setLinks(currentLinks);
    // checking if any description is empty
    for (const [key, value] of Object.entries(currentTextAreaValues)) {
      if (value === '') {
        message += ` \nRemplizez la description: ${key}`;
        formValid = false;
        textAreaRef.current.classList.add('invalid_field');
      }
    }
    const descriptionData = JSON.stringify(currentTextAreaValues);
    formData.set('description', descriptionData);
    // checking if any description is empty
    for (const [key, value] of Object.entries(currentTitles)) {
      if (value === '') {
        message += ` \nRemplizez le titre: ${key}`;
        formValid = false;
        inputTitleRef.current.classList.add('invalid_field');
      }
    }
    const titleData = JSON.stringify(currentTitles);
    formData.set('title', titleData);
    // checking if any link is empty (only if we have a link to share)
    if (
      currentLinks.fr !== '' ||
      currentLinks.en !== '' ||
      currentLinks.esp !== '' ||
      currentLinks.link !== ''
    ) {
      for (const [key, value] of Object.entries(currentLinks)) {
        if (value === '') {
          message += ` \nRemplizez le titre du lien: ${key}`;
          formValid = false;
          linkTitleRef.current.classList.add('invalid_field');
        }
        if (currentLinks.link === '') {
          message += ' \nRemplisez le lien du post';
          formValid = false;
          linkRef.current.classList.add('invalid_field');
        }
      }
      const linkData = JSON.stringify(currentLinks);
      formData.set('links', linkData);
    }
    // seting the imagesData to the formData object if we have a image at least
    if (affiche !== '') {
      formData.append('image', affiche);
      files.forEach((file) => {
        if (file.path !== affiche.path) {
          formData.append('image', file);
        }
      });
    }
    if (formValid) {
      if (!itemToUpdate) {
        try {
          // insert query function
          const response = await insertPost(formData);
          if (response.error) {
            window.alert(response.error);
            console.log(response);
          } else {
            window.alert('Post ajouté correctement');
            window.location.reload();
          }
        } catch (error) {
          console.error('Error al ajouter le post:', error);
          window.alert(
            'Error al ajouter le poste. Veuillez réessayer plus tard.'
          );
        }
      } else {
        const imagesToDelete = itemToUpdate.images.filter(
          (image) => !prevImages.includes(image)
        );
        const imagesToSave = itemToUpdate.images.filter((image) =>
          prevImages.includes(image)
        );
        formData.set('imagesToDelete', JSON.stringify(imagesToDelete));
        formData.set('imagesToSave', JSON.stringify(imagesToSave));
        formData.set('id', itemToUpdate.id);
        try {
          const response = await updatePost(formData);
          if (response.error) {
            window.alert(response.error);
            console.log(response);
          } else {
            window.alert('Spectacle mis à jour correctement');
            window.location.reload();
          }
        } catch (error) {
          console.error('Error al actualizar el post:', error);
          window.alert(
            'Error al actualizar el post. Veuillez réessayer plus tard.'
          );
        }
      }
    } else {
      window.alert(message);
    }
  };
  return (
    <div className="admin_insert_post">
      <h3>{itemToUpdate != null ? 'Actualizer post' : 'Nouvel post'}</h3>
      <div className="admin_description_bts">
        <button onClick={(e) => handleLanguageChange(e, 'fr')}>French</button>
        <button onClick={(e) => handleLanguageChange(e, 'esp')}>Spanish</button>
        <button onClick={(e) => handleLanguageChange(e, 'en')}>English</button>
        <button onClick={(e) => handleTraduction(e)}>Traduir</button>
      </div>
      <article className="admin_feed_preview">
        <form onSubmit={handleSubmit}>
          <div className="title_container_insert_feed">
            <input
              id="input_title"
              ref={inputTitleRef}
              type="text"
              placeholder={`Title ${currentLanguage}`}
            />
            <span>
              <CalendarMonthIcon /> {new Date().toLocaleDateString()}
            </span>
          </div>
          <div className="ln_separator"></div>
          <div className="container_images_post">
            <div
              {...getRootProps()}
              className="feed_form_drop_box"
              style={{
                backgroundColor: isDragActive ? '#3e3e3ec7' : 'none',
                backgroundImage: handleBackgroundDrop(),
                outline:
                  affiche !== '' ? 'white 1px solid' : 'white 2px dashed',
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>{affiche !== '' ? '' : 'Suelta las imagenes aqui...'} </p>
              ) : (
                <p>
                  {affiche !== '' ? '' : 'La primer imagen será el affiche...'}
                </p>
              )}
            </div>
            <aside className="feed_mini_images">
              {
                // preview of the new images added
                thumbs
              }
              {
                // preview of the existing images
                prevThumbs
              }
            </aside>
          </div>
          <TextArea
            ref={textAreaRef}
            placeholder={`Text ${currentLanguage}`}
            width={'100%'}
            minHeight="70px"
          />
          <div className="insert_post_buttons">
            <label>
              Ajoute un link ici (Optionnel) <br />
              <input
                id="link_title"
                type="text"
                ref={linkTitleRef}
                placeholder={`Link title ${currentLanguage}`}
              />
              <input type="text" ref={linkRef} placeholder={'Url'} />
            </label>
            <div>
              <button type="submit">Valider</button>
              <button onClick={handleBackdrop}>Annuler</button>
            </div>
          </div>
        </form>
      </article>
    </div>
  );
}
